import { createQtStore } from "@quintype/framework/store/create-store";
import { renderComponent } from "@quintype/framework/client/start";

import "../assets/stylesheets/app.scss";
import Header from "../isomorphic/components/rows/header";
import Footer from "../isomorphic/components/rows/footer";

export const layout = (host = "http://localhost:3000") => {
  window
    .fetch(`${host}/v/api/layout.json?only-data=true`)
    .then(result => result.json())
    .then(data => {
      const store = createQtStore({}, data, {});
      window.userPromise = window.fetch(`${host}/api/vikatan/v1/users/me`).then(response => response.json());
      renderComponent(Header, "qt-vikatan-header", store, {});
      renderComponent(Footer, "qt-vikatan-footer", store, {});
    })
    .catch(console.log);
};

window.qtLayout = layout;
